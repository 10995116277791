import { ref } from 'vue';

import { i18n } from '@/plugins/localization';
import { copyTextToClipboard } from '@/utils/functions';

const css = (element, style) => {
  Object.entries(style).forEach((el) => {
    const [rule, value] = el;
    element.style[rule] = value;
  });
};

const createTooltipElement = (styles, bindingParams) => {
  const exsitingTooltip = document.querySelector('.inner-tooltip-directive');

  if (!exsitingTooltip) {
    const Tooltip = document.createElement('div');

    const { t } = i18n.global;
    Tooltip.classList.add('inner-tooltip-directive');
    Tooltip.innerText = bindingParams.isCopied ? t('common.copy') : (bindingParams.text || bindingParams.value);
    css(Tooltip, styles);

    document.body.appendChild(Tooltip);
  }
};

const updateTooltipParams = (styles) => {
  const Tooltip = document.querySelector('.inner-tooltip-directive');
  if (Tooltip) {
    css(Tooltip, styles);
  }
};

export default {
  mounted(el, binding) {
    const { t } = i18n.global;
    const bindingParams = binding.modifiers;

    const element = el;
    if (bindingParams.isCopied && element) {
      element.classList.add('cursor-pointer');
    }

    const position = ref({
      top: 0,
      left: 0,
    });

    const getDeltaX = (event) => {
      let deltaX = 0;
      const Tooltip = document.querySelector('.inner-tooltip-directive');

      if (Tooltip) {
        const tipWidth = Tooltip.getBoundingClientRect().width;
        deltaX = event.pageX - tipWidth * 0.5;

        const windowWidth = event.view.innerWidth || event.view.clientX;

        if (deltaX + tipWidth > windowWidth) {
          deltaX = windowWidth - tipWidth;
        }
      }

      return deltaX;
    };

    const styles = {
      position: 'fixed',
      zIndex: 999,
      background: 'var(--color-black-07)',
      color: '#FFFFFF',
      padding: '8px 12px',
      borderRadius: '6px',
      fontSize: '13px',
      lineHeight: 1.5,
      display: 'none',
      width: bindingParams.width || 'auto',
      top: position.value.top,
      left: position.value.left,
    };

    createTooltipElement(styles, bindingParams);

    el.onMouseEnter = () => {
      const Tooltip = document.querySelector('.inner-tooltip-directive');
      Tooltip.innerText = bindingParams.isCopied ? t('common.copy') : (bindingParams.text || bindingParams.value);

      updateTooltipParams({ display: bindingParams.isDisabled === true ? 'none' : 'block', width: bindingParams.width || 'auto' });
    };
    el.onMouseLeave = () => {
      const Tooltip = document.querySelector('.inner-tooltip-directive');
      Tooltip.innerText = bindingParams.isCopied ? t('common.copy') : (bindingParams.text || bindingParams.value);

      updateTooltipParams({ display: 'none' });
    };
    el.onMouseMove = (event) => {
      position.value.left = `${getDeltaX(event)}px`;
      position.value.top = `${event.clientY + 16}px`;
      updateTooltipParams({
        top: position.value.top,
        left: position.value.left,
      });
    };

    el.onClick = () => {
      const Tooltip = document.querySelector('.inner-tooltip-directive');

      if (Tooltip) {
        if (bindingParams.isCopied && element) {
          const textFromProps = bindingParams.value;
          const textFromSlot = Tooltip.innerText;

          const text = textFromProps || textFromSlot;
          copyTextToClipboard(String(text));
          element.classList.add('blink-text');
          Tooltip.innerText = t('common.copied');

          setTimeout(() => {
            element.classList.remove('blink-text');
          }, 250);

          setTimeout(() => {
            Tooltip.innerText = t('common.copy');
          }, 1250);
        }
      }
    };

    element.addEventListener('mouseenter', el.onMouseEnter);
    element.addEventListener('mouseleave', el.onMouseLeave);
    element.addEventListener('mousemove', el.onMouseMove);

    element.addEventListener('click', el.onClick);
  },
  beforeUnmount(el) {
    const element = el.parentElement;

    element.removeEventListener('mouseenter', el.onMouseEnter);
    element.removeEventListener('mouseleave', el.onMouseLeave);
    element.removeEventListener('mousemove', el.onMouseMove);

    element.removeEventListener('click', el.onClick);
  },
};
