import { computed } from 'vue';

export const useProps = (params) => {
  const column = computed(() => params?.colDef);
  const row = computed(() => params?.data);
  const item = computed(() => row.value && row.value[column.value?.field]);

  const isEmptyRow = computed(() => params.data?.isEmptyRow);

  return {
    column,
    row,
    item,

    isEmptyRow,
  };
};
