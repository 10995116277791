const STATUS_PREFIX = 'status.';
const STATUS_COLOR_PREFIX = '-status-color';

export const STATUS = Object.freeze({
  CONFIRMED: `${STATUS_PREFIX}CONFIRMED`,
  UNCONFIRMED: `${STATUS_PREFIX}UNCONFIRMED`,
  NEW: `${STATUS_PREFIX}NEW`,
  PRE_NEW: `${STATUS_PREFIX}PRE_NEW`,
  EXPIRED: `${STATUS_PREFIX}EXPIRED`,
  COMPLETE: `${STATUS_PREFIX}COMPLETE`,
  PROGRESS: `${STATUS_PREFIX}PROGRESS`,
  ACTIVE: `${STATUS_PREFIX}ACTIVE`,
  INACTIVE: `${STATUS_PREFIX}INACTIVE`,
  OPEN: `${STATUS_PREFIX}OPEN`,
  CANCELLED: `${STATUS_PREFIX}CANCELLED`,
  OVERDUE: `${STATUS_PREFIX}OVERDUE`,
  PAID: `${STATUS_PREFIX}PAID`,
  UNPAID: `${STATUS_PREFIX}UNPAID`,
  HOLD: `${STATUS_PREFIX}HOLD`,
  SUSPENDED: `${STATUS_PREFIX}SUSPENDED`,
  SUCCESS: `${STATUS_PREFIX}SUCCESS`,
  FAIL: `${STATUS_PREFIX}FAIL`,
  FAILED: `${STATUS_PREFIX}FAILED`,
  BOUNCED: `${STATUS_PREFIX}BOUNCED`,
  DELIVERED: `${STATUS_PREFIX}DELIVERED`,
  QUEUE: `${STATUS_PREFIX}QUEUE`,
  ERROR: `${STATUS_PREFIX}ERROR`,
  DO_NOT_DISTURB: `${STATUS_PREFIX}DO_NOT_DISTURB`,
  BLOCKED: `${STATUS_PREFIX}DO_NOT_DISTURB`,
  UNDERPAID: `${STATUS_PREFIX}UNDERPAID`,
  QUEUED: `${STATUS_PREFIX}QUEUED`,
  COMPLETED: `${STATUS_PREFIX}COMPLETED`,
  APPROVALPENDING: `${STATUS_PREFIX}APPROVALPENDING`,
  ARCHIVED: `${STATUS_PREFIX}ARCHIVED`,
  DRAFT: `${STATUS_PREFIX}DRAFT`,
  DISABLED: `${STATUS_PREFIX}DISABLED`,
  PENDING: `${STATUS_PREFIX}PENDING`,
  WAITING: `${STATUS_PREFIX}WAITING`,
  UNREGISTERED: `${STATUS_PREFIX}UNREGISTERED`,

  DELETED: `${STATUS_PREFIX}DELETED`,
  PRUNED: `${STATUS_PREFIX}PRUNED`,
});

export const COLORS = Object.freeze({
  CONFIRMED: `success${STATUS_COLOR_PREFIX}`,
  UNCONFIRMED: `danger${STATUS_COLOR_PREFIX}`,

  INACTIVE: `inactive${STATUS_COLOR_PREFIX}`,
  DELETED: `deleted${STATUS_COLOR_PREFIX}`,
  SUSPENDED: `danger${STATUS_COLOR_PREFIX}`,
  PRUNED: `cancelled${STATUS_COLOR_PREFIX}`,

  NEW: `white${STATUS_COLOR_PREFIX}`,
  PEW_NEW: `white${STATUS_COLOR_PREFIX}`,
  EXPIRED: `default${STATUS_COLOR_PREFIX}`,
  COMPLETE: `success${STATUS_COLOR_PREFIX}`,
  PROGRESS: `info${STATUS_COLOR_PREFIX}`,
  ACTIVE: `success${STATUS_COLOR_PREFIX}`,
  OPEN: `success${STATUS_COLOR_PREFIX}`,
  CANCELLED: `cancelled${STATUS_COLOR_PREFIX}`,
  OVERDUE: `primary${STATUS_COLOR_PREFIX}`,
  PAID: `success${STATUS_COLOR_PREFIX}`,
  UNPAID: `primary${STATUS_COLOR_PREFIX}`,
  HOLD: `primary${STATUS_COLOR_PREFIX}`,
  SUCCESS: `success${STATUS_COLOR_PREFIX}`,
  FAIL: `danger${STATUS_COLOR_PREFIX}`,
  FAILED: `danger${STATUS_COLOR_PREFIX}`,
  BOUNCED: `bounced${STATUS_COLOR_PREFIX}`,
  DELIVERED: `success${STATUS_COLOR_PREFIX}`,
  QUEUE: `primary${STATUS_COLOR_PREFIX}`,
  ERROR: `error${STATUS_COLOR_PREFIX}`,
  DO_NOT_DISTURB: `blocked${STATUS_COLOR_PREFIX}`,
  BLOCKED: `blocked${STATUS_COLOR_PREFIX}`,
  UNDERPAID: `bounced${STATUS_COLOR_PREFIX}`,
  QUEUED: `white${STATUS_COLOR_PREFIX}`,
  COMPLETED: `success${STATUS_COLOR_PREFIX}`,
  APPROVALPENDING: `primary${STATUS_COLOR_PREFIX}`,
  ARCHIVED: `default${STATUS_COLOR_PREFIX}`,
  DRAFT: `draft${STATUS_COLOR_PREFIX}`,
  DISABLED: `disabled${STATUS_COLOR_PREFIX}`,
  PENDING: `pending${STATUS_COLOR_PREFIX}`,
  WAITING: `info${STATUS_COLOR_PREFIX}`,
  UNREGISTERED: `default${STATUS_COLOR_PREFIX}`,
});

export const STATUS_SELECT = Object
  .keys(STATUS)
  .map((key) => ({
    id: key,
    key,
    title: STATUS[key],
    color: COLORS[key],
  }));

export const allStatus = {
  id: 'all',
  key: 'all',
  title: `${STATUS_PREFIX}all`,
};

export const getStatuses = (list = []) => {
  const all = [];
  list.forEach((item) => {
    if (item === 'all') {
      all.push(allStatus);
    } else {
      const status = STATUS_SELECT.find((el) => el.key === item);
      if (status) {
        all.push(status);
      }
    }
  });
  return all;
};
