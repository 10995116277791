import StatusCellRenderer from '@/components/Modules/AgGrid/Cells/cellRenderers/StatusCellRenderer';

export const TABLE_TYPES = {
  ID: 'id',
  UUID: 'uuid',
  UUID_MODAL: 'uuid-modal',
  NAME: 'name',
  DATE: 'date',
  DATE_TIME: 'date-time',
  TIME: 'time',
  STATUS: 'status',
  CRYPTO_PAIR: 'crypto-pair',
  CRYPTO_TEXT: 'crypto-text',
  USER_TYPE: 'user-type',
  CURRENCY: 'currency',
  CRYPTO: 'crypto',
  OPTIONS: 'options',
  PAYMENT_TYPE: 'payment-type',
  PERSON: 'person',
  CYCLE: 'cycle',
  IP: 'ip',
  PLAN: 'plan',
  ROW_SELECTION: 'row-selection',
  TEXT_COPY: 'text-copy',
};

export const statusCellOptions = {
  name: 'status',
  isShow: true,
  isPermanent: true,
  width: 150,
  minWidth: 120,
  suppressSizeToFit: true,
  resizable: false,
  type: 'Custom',
  render: StatusCellRenderer,
};

export const rowSelectionCellOptions = {
  name: 'CHECKBOX',
  headerCheckboxSelection: true,
  checkboxSelection: true,
  isShow: true,
  isPermanent: true,
  width: 40,
  resizable: false,
  type: TABLE_TYPES.ROW_SELECTION,
  nonSelectable: true,
};
