<template>
  <div
    class="cell-status ellipsis"
    :style="{
      '--badge-background-color': badgeBackgroundColor,
      '--badge-color': badgeTextColor,
      '--badge-border-color': badgeBorderColor,
    }"
    :class="{
      'line-through': isAdminDeletedStatus,
      'is-deleted-status': isAdminDeletedStatus,
    }"
  >
    <AppText size="13px" class="ellipsis font-medium" :line-height="1.21">{{ item ? statusTitle : null }}</AppText>
    <AppIcon
      v-if="isUnderpaidAndExpired || isExpiredWhileConfirmed || isCompleteManual"
      name="info-circle-big"
      size="12px"
      style="margin-left: 4px; margin-top: 1px;"
      :fill="isCompleteManual ? '#236400' : null"
      :opacity="isCompleteManual ? 1 : 0.5"
    />
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

import colors from '@/styles/colors';

import { COLORS, STATUS } from '@/common/status';
import { getStatusHints } from '@/utils/functions';
import { useToken } from '@/composables/useToken';
import tooltip from '@/directives/tooltip';
import { i18n } from '@/plugins/localization';

import { useProps } from './useProps';

export default defineComponent({
  directives: {
    tooltip,
  },
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { column, row, item } = useProps(props.params);

    const { isAdminRole } = useToken();

    const type = computed(() => COLORS[item.value]);
    const adminType = computed(() => `ADMIN${COLORS[item.value]}`);

    const badgeBackgroundColor = computed(() => (isAdminRole.value && colors[`${adminType.value}`]
      ? colors[`${adminType.value}`]
      : colors[`${type.value}`]));
    const badgeTextColor = computed(() => (isAdminRole.value && colors[`t${adminType.value}`]
      ? colors[`t${adminType.value}`]
      : colors[`t${type.value}`]));
    const badgeBorderColor = computed(() => (isAdminRole.value && colors[`b${adminType.value}`]
      ? colors[`b${adminType.value}`]
      : colors[`b${type.value}`]));

    const { isUnderpaidAndExpired, isCompleteManual, isExpiredWhileConfirmed } = getStatusHints(row);

    const isAdminDeletedStatus = isAdminRole.value && item.value === 'DELETED';

    const { t } = i18n.global;
    const statusTitle = computed(() => item.value === 'DO_NOT_DISTURB' ? 'DND' : t(STATUS[item.value]));

    return {
      column,
      item,
      type,
      statusTitle,

      badgeBackgroundColor,
      badgeTextColor,
      badgeBorderColor,

      STATUS,
      isUnderpaidAndExpired,
      isCompleteManual,
      isExpiredWhileConfirmed,

      isAdminDeletedStatus,
    };
  },
});
</script>

<style lang="scss">
@import './styles';
</style>
