<template>
  <div :key="isLoading" class="linear__progress" :class="{ 'is-loading': isLoading }" />
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.linear__progress {
  height: 4px;
  background-color: #f7e1cc;
  border-radius: 10px;
  margin: 0 auto;
  position: absolute;
  top: 36px;
  right: 100%;
  bottom: 0;
  left: 0;
  width: 0%;
  animation: borealisBar 1s linear infinite;

  opacity: 0;
  &.is-loading {
    opacity: 1;
  }
}

@keyframes borealisBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}
</style>
