<template>
  <FTabs
    v-model="currentTab"
    :options="options"
    v-bind="$attrs"
    class="tabs"
    has-colored-titles
  >
    <template #label="{ item }">
      <span class="d-flex align-items-center">
        <span
          class="tab-text"
          :class="$attrs.type === 'card' ? '' : 'font-medium'"
        >
          <slot name="title" :item="item">{{ item.text }}</slot>
        </span>
        <BlurLoading v-if="otherTab && item.value === otherTab?.name" :is-loading="isLoading" style="width: 18px;">
          <AppText
            :line-height="3"
            :class="$attrs.type === 'card' ? '' : 'font-medium'"
            is-block
            ml="4px"
          >
            ({{ otherTab.value }})
          </AppText>
        </BlurLoading>
      </span>
    </template>
  </FTabs>
  <div
    class="tab-container"
    :class="{
      'tab-container--card': $attrs.type === 'card',
      'has-min-height': hasMinHeight,
      'full-height': hasFullHeight,
    }"
  >
    <slot />
  </div>
</template>

<script setup>
import BlurLoading from '@/components/Animation/BlurLoading.vue';

defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  otherTab: {
    type: Object,
    default: () => {},
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  hasMinHeight: {
    type: Boolean,
    default: true,
  },
  hasFullHeight: {
    type: Boolean,
    default: false,
  },
  minHeight: {
    type: String,
    default: '420px',
  },
});

const currentTab = defineModel({ type: String, default: '' });
</script>

<style scoped lang="scss">
.has-colored-titles:deep(.el-tabs__item) {
  .tab-text, .item-wrapper span.text {
    color: var(--color-black);
    @include transition-base;
  }
  .item-wrapper span.text {
    color: #999;
  }
  &.is-active {
    .tab-text, .item-wrapper span.text {
      color: var(--color-primary);
      opacity: 1;
    }
  }
  &:hover:not(.is-active, .is-disabled) {
    .tab-text, .item-wrapper span.text {
      color: var(--color-primary);
    }
  }
}

.tab-container {
  position: relative;
  width: 100%;
  height: 100%;
  &.has-min-height {
    min-height: v-bind(minHeight);
  }
}

:deep(.el-tabs__header) {
  margin-bottom: 0;
}

.tabs {
  :deep(.el-tabs__nav-scroll) {
    padding: 0 40px;
  }
  :deep(.el-tabs__item) {
    text-transform: initial;
    color: var(--color-black) !important;
    display: inline-flex;
    align-items: center;
  }
  :deep(.el-skeleton) {
    display: flex;
    align-items: center;
  }
}

:deep(.el-tabs--card) {
  .el-tabs__nav {
    display: flex;
    gap: 8px;
    border: 0;
  }
  .el-tabs__nav-scroll {
    padding: 0;
  }

  .el-tabs__item {
    margin-right: 0 !important;
    padding: 0 18px !important;
    border: 1px solid var(--el-border-color-light) !important;
    border-radius: 8px 8px 0 0;
    background: var(--color-black-005);
    color: #939393 !important;
    @include font-regular;
    @include transition-base;

    &:hover:not(.is-active) {
      border-bottom: unset;
    }

    &.is-active {
      background: #ffffff;
      color: var(--color-black)  !important;
      border-bottom: 1px solid #fff !important;
    }
  }
}

.tab-container--card {
  border-right: 1px solid var(--el-border-color-light) !important;
  border-left: 1px solid var(--el-border-color-light) !important;
  border-bottom: 1px solid var(--el-border-color-light) !important;
  border-radius: 0 0 8px 8px;
}

</style>
