<template>
  <div class="stablecoin-icon">
    <AppIcon
      :key="icon"
      :name="icon"
      :size="size"
    />
    <AppIcon
      :key="familyIcon && isDisabled"
      :name="familyIcon"
      class="stablecoin-icon-sup"
      :size="`${parseInt(size) / 1.6}px`"
    />
  </div>
</template>

<script>
export default {
  name: 'FamilyIcon',
  props: {
    icon: {
      type: String,
      default: '',
    },
    familyIcon: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '28px',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.stablecoin-icon {
  @include flex-center;
  position: relative;

  .stablecoin-icon-sup {
    position: absolute;
    top: -15%;
    right: -25%;
    border-radius: 50%;
  }
}
</style>
