import { h, withDirectives } from 'vue';

import { i18n } from '@/plugins/localization';

import { getStatusHints } from '@/utils/functions';
import CellStatus from '@/components/Modules/AgGrid/Cells/CellStatus.vue';
import tooltip from '@/directives/tooltip';

export default {
  renderFunction: ({ column, row, item }) => {
    const { t } = i18n.global;
    
    const { isUnderpaidAndExpired, isCompleteManual, isExpiredWhileConfirmed } = getStatusHints(row);
    const hasAnnotation = isUnderpaidAndExpired.value || isCompleteManual.value || isExpiredWhileConfirmed.value;

    let textInTooltip = '';
    if (isUnderpaidAndExpired.value) {
      textInTooltip = t('merchantPayments.label.statusTooltip.isUnderpaidAndExpired');
    }
    if (isExpiredWhileConfirmed.value) {
      textInTooltip = t('merchantPayments.label.statusTooltip.isExpiredWhileConfirmed');
    }
    if (isCompleteManual.value) {
      textInTooltip = t('merchantPayments.label.statusTooltip.isCompleteManual');
    }

    const CELL = h(CellStatus, { params: { colDef: column.value, data: row.value } }, () => item.value);
    const DIRECTIVE = [tooltip, item.value, '', {
      text: textInTooltip,
      isDisabled: !hasAnnotation,
      width: '250px',
    }];

    return withDirectives(CELL, [DIRECTIVE]);
  },
  hasParentBlock: false,
};
