<template>
  <div
    class="item-wrapper"
    :class="{ 'is-loading': isLoading }"
  >
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
.item-wrapper {
  filter: blur(0);
  @include transition-base(filter);
}

.is-loading {
  filter: blur(6px);
}
</style>
